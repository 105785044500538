export default {

	computed: {

		path: function() {

			return this.$route.path

		}

	},

	methods: {

		$link: function(path, forceContext, forceLanguage) {

			forceContext = forceContext || false
			forceLanguage = forceLanguage || false

			if (path === undefined) return '/'

			if (path == '#') {

				return {
					path: '/#'
				}

			} else {

				if (forceLanguage) {

					if (forceLanguage === true) {

						var language = this.$store.getters['geolocation']

						if (language) {

							var found = false

							this.$_.each(this.$store.getters['languages'], function(candidate) {

								if (candidate.code === language || this.$_.contains(candidate.alternatives, language)) {

									found = true
									forceLanguage = candidate.code

								}

							}.bind(this))

							if (!found) {

								forceLanguage = false

							}

						} else {

							forceLanguage = false

						}

					}

				}

				if (forceLanguage === false) path = this.$store.getters['language'].code.toLowerCase() + '/' + path
				if (forceContext === false && path !== this.$store.getters['context'].url) path = this.$store.getters['context'].url + '/' + path
				if (forceLanguage !== false) path = forceLanguage.toLowerCase() + '/' + path
				if (forceContext !== false) path = forceContext + '/' + path

				path = path.replace('//', '/')
	
				return {
					path: (path[0] === '/') ? path : '/' + path
				}
	
			}

		}

	}

}