import axios from 'axios'
import pubsub from '@/pubsub'
import live from '@/live'
import _ from 'underscore'

const CancelToken = axios.CancelToken

var api = document.getElementById('app').getAttribute('data-api')

export default {

	api: api,

	source: {},

	cancel: function(id) {

		if (this.source[id]) this.source[id].cancel()

	},

	get: function(url, params, id) {

		params = params || {}
		id = id || url

		this.source[id] = CancelToken.source()

		return new Promise(function(resolve, reject) {

			axios.get(this.api + url + '.json', {
				cancelToken: this.source[id].token,
				params: params
			}).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function(e) {

				this.response(reject, e.response)

			}.bind(this))

		}.bind(this))

	},

	post: function(url, params) {

		params = params || {}

		return new Promise(function(resolve, reject) {

			axios.post(this.api + url + '.json', params).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function(e) {

				this.response(reject, e.response)

			}.bind(this))

		}.bind(this))

	},

	put: function(url, params) {

		params = params || {}

		return new Promise(function(resolve, reject) {

			axios.put(this.api + url + '.json', params).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function(e) {

				this.response(reject, e.response)

			}.bind(this))

		}.bind(this))

	},

	patch: function(url, params) {

		params = params || {}

		return new Promise(function(resolve, reject) {

			axios.patch(this.api + url + '.json', params).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function(e) {

				this.response(reject, e.response)

			})

		}.bind(this))

	},

	delete: function(url) {

		return new Promise(function(resolve, reject) {

			axios.delete(this.api + url + '.json').then(function(response) {

				this.response(resolve, response)

			}.bind(this), function(e) {

				this.response(reject, e.response)

			}.bind(this))

		}.bind(this))

	},

	response: function(resolve, response) {

		var events = response.data.events || []

		_.each(events, function(event) {

			if (!live.$received(event.id)) pubsub.$emit(event.name, event.params)

		})

		resolve(response.data.data)

	}

}