export default {

	buttonAlign: {
		left: 1,
		center: 2,
		right: 3
	},

	pageTypeIndex: {
		default: 1,
		redirect: 3,
		news: 4,
		event: 5,
		product: 6,
		condition: 7,
		symptom: 8,
		treatment: 9,
		home: 10
	},

	bannerLayout: {
		menu: {
			siblings: 1,
			children: 2
		},
		overlap: 3,
		hide: 4
	},

	leftLayout: {
		menu: {
			parents: 3,
			siblings: 1,
			children: 2
		}
	},

	rightLayout: {
		cta: 1,
		share: 2,
		donate: 3,
		adverts: 4,
		news: 5,
		avatar: 6
	},

	context: {
		aa: 1,
		afa: 2,
		stars: 3
	},

	pageType: {
		7: 'Condition',
		8: 'Symptom',
		9: 'Treatment'
	},

	inputType: {
		textbox: 1,
		textarea: 2,
		select: 3,
		toggle: 4,
		checklist: 5,
		file: 7,
		title: 8
	},

	fileType: {
		1: '.png,.gif,.jpg,.jpeg',
		2: '.doc,.docx',
		3: '.pdf'
	},

	blockType: {
		text: 1,
		image: 2,
		featuredProfile: 3,
		profiles: 4,
		news: 5,
		resources: 6,
		products: 7,
		featuredEvents: 8,
		events: 9,
		product: 10,
		embed: 11,
		form: 12,
		symptoms: 13,
		treatments: 14,
		cta: 15,
		links: 16,
		conditions: 17,
		videos: 18,
		textImage: 19,
		homePages: 20,
		homeSearch: 21,
		homeHelp: 22,
		homeEvents: 23,
		homeCharities: 24,
		textColumns: 25,
		directoryApplication: 26,
		directorySearch: 27
	},

	resourceLayout: {
		list: 1,
		cards: 2,
		table: 3
	},

	linksLayout: {
		list: 1,
		cards: 2
	},

	newsTags: {
		1: 'Headlines',
		2: 'News',
		3: 'Press release',
		4: 'Newsletter',
		5: 'E-newsletter'
	}

}