<template>

<div class="page" :class="{'is-overlap': isOverlap, 'is-locked': is.searching, 'is-fluid': isHome, 'is-landing': isLanding, 'is-tabbed': isTabbed, [clsContext]: true}">

	<com-head :head="head" v-if="!isLanding && initialLoaded" v-on:search="is.searching = true" />

	<com-banner :page="page" :layout="layout" v-if="!isHome && !isHideBanner && initialLoaded" />

	<div class="page-content" v-if="initialLoaded">

		<div class="page-content-left" v-if="page.layout.left.length">

			<com-layout-left-menu :links="layout.left[$constants.leftLayout.menu.parents]" v-if="$_.contains(page.layout.left, $constants.leftLayout.menu.parents)" />
			<com-layout-left-menu :links="layout.left[$constants.leftLayout.menu.siblings]" v-if="$_.contains(page.layout.left, $constants.leftLayout.menu.siblings)" />
			<com-layout-left-menu :links="layout.left[$constants.leftLayout.menu.children]" v-if="$_.contains(page.layout.left, $constants.leftLayout.menu.children)" />

		</div>

		<div class="page-content-blocks">

			<com-block v-for="(block, index) in page.blocks" :key="page.id + '/' + $store.getters['context'].url + '/' + $store.getters['language'].code.toLowerCase() + '/' + index" :block="block" :page="page" />

		</div>

		<div class="page-content-right" v-if="page.layout.right.length">

			<com-layout-right-avatar v-if="$_.contains(page.layout.right, $constants.rightLayout.avatar)" />
			<com-layout-right-adverts v-if="$_.contains(page.layout.right, $constants.rightLayout.adverts)" />
			<com-layout-right-news :links="layout.right[$constants.rightLayout.news]" v-if="$_.contains(page.layout.right, $constants.rightLayout.news)" />
			<com-layout-right-donate v-if="$_.contains(page.layout.right, $constants.rightLayout.donate)" />
			<com-layout-right-cta v-if="$_.contains(page.layout.right, $constants.rightLayout.cta)" />
			<com-layout-right-share :links="page.share" v-if="$_.contains(page.layout.right, $constants.rightLayout.share)" />

		</div>

	</div>

	<com-foot :foot="foot" :is-landing="isLanding" v-if="initialLoaded" />

	<com-search v-if="is.searching" v-on:close="is.searching = false" />

</div>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-block': () => import('./page/Block'),
		'com-head': () => import('./page/Head'),
		'com-banner': () => import('./page/Banner'),
		'com-foot': () => import('./page/Foot'),
		'com-search': () => import('./page/Search'),
		'com-layout-left-menu': () => import('./page/layout/left/Menu'),
		'com-layout-right-donate': () => import('./page/layout/right/Donate'),
		'com-layout-right-avatar': () => import('./page/layout/right/Avatar'),
		'com-layout-right-cta': () => import('./page/layout/right/Cta'),
		'com-layout-right-share': () => import('./page/layout/right/Share'),
		'com-layout-right-adverts': () => import('./page/layout/right/Adverts'),
		'com-layout-right-news': () => import('./page/layout/right/News')
	},

	data: function() {

		return {
			initialLoaded: false,
			is: {
				searching: false
			},
			head: {
				primary: [],
				secondary: [],
				langauges: []
			},
			foot: {
				links: [],
				social: [],
				subscribe: {}
			},
			page: {
				path: [],
				blocks: [],
				layout: {
					left: [],
					right: [],
					banner: []
				}
			},
			layout: {}
		}
	},

	watch: {

		path: function() {

			this.load()

		}

	},

	created: function() {

		if (this.$route.meta.landing) {

			if (!this.$store.getters['geolocation']) this.$store.dispatch('geolocate')

		}

		this.load()

	},

	computed: {

		clsContext: function() {

			return 'is-' + this.$store.getters['context'].url

		},

		isTabbed: function() {

			return this.$_.contains(this.page.layout.banner, this.$constants.bannerLayout.menu.siblings) || this.$_.contains(this.page.layout.banner, this.$constants.bannerLayout.menu.children)

		},

		isHideBanner: function() {

			return this.$_.contains(this.page.layout.banner, this.$constants.bannerLayout.hide)

		},

		isOverlap: function() {

			return this.$_.contains(this.page.layout.banner, this.$constants.bannerLayout.overlap)

		},

		isHome: function() {

			return this.page.type === this.$constants.pageTypeIndex.home

		},

		isLanding: function() {

			return this.$route.path === '/'

		}

	},

	methods: {

		load: function() {

			this.$api.get('page', {
				path: this.$route.path
			}).then(function(json) {

				this.$store.commit('context', json.context)
				this.$store.commit('language', json.language)
				this.$store.commit('languages', json.head.languages)

				Vue.set(this, 'page', json.page)
				Vue.set(this, 'head', json.head)
				Vue.set(this, 'foot', json.foot)
				Vue.set(this, 'layout', json.layout)

				document.title = json.page.meta.title
				document.querySelector('meta[name="description"]').setAttribute("content", json.page.meta.description);

				window.scrollTo(0, 0)

				this.initialLoaded = true

				// eslint-disable-next-line
				gtag('set', 'page', this.$route.fullPath)

				// eslint-disable-next-line
				gtag('send', 'pageview')

			}.bind(this), function(json) {

				if (json.redirect) {

					window.location.href = json.redirect

				} else {

					this.initialLoaded = true

					this.$router.push({
						path: '/404'
					})

				}

			}.bind(this))

		}
	
	}

}

</script>

<style scoped>

.page {
	padding-top: 112px;
}

.page.is-locked {
	overflow: hidden;
	max-height: 100vh;
}

.is-tablet .page {
	padding-top: 80px;
}

.is-mobile .page {
	padding-top: 96px;
}

.page.is-landing {
	padding-top: 0px;
}

.page-content {
	display: flex;
	padding: 40px 20px;
	max-width: 1120px;
	margin: 0px auto;
}

.is-device .page-content {
	flex-direction: column;
	padding: 20px;
}

.is-mobile .page-content {
	padding: 20px 10px;
}

.page.is-fluid .page-content {
	padding: 0px;
	max-width: 100%;
}

.page.is-overlap .page-content {
	margin-top: -64px;
	background-color: #fff;
	border-radius: 17px;
}

.page-content-left {
	width: 242px;
	flex-shrink: 0;
	margin-right: 40px;
}

.is-device .page-content-left {
	width: 100%;
	margin-right: 0px;
	margin-bottom: 20px;
}

.page-content-blocks {
	flex-grow: 1;
}

.page-content-right {
	width: 242px;
	flex-shrink: 0;
	margin-left: 40px;
}

.is-device .page-content-right {
	width: 100%;
	margin-left: 0px;
	margin-top: 20px;
}

</style>
