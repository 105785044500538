<template>

<div id="app" :class="{'is-mobile': window.is.mobile, 'is-device': !window.is.desktop, 'is-tablet': window.is.tablet, 'is-desktop': window.is.desktop, 'is-embed': $route.meta.embed}">

	<router-view></router-view>

	<div class="region" v-if="prompt.show">

		<div class="region-text">Hi! We noticed you're location within <b>{{ prompt.location }}</b> but you're browsing the <b>{{ prompt.current }}</b> website. Would you like to go to our {{ prompt.location }} website instead?</div>

		<div class="region-buttons">
			<button class="region-buttons-item" v-on:click="onFollowPrompt">Yes</button>
			<button class="region-buttons-item" v-on:click="onClosePrompt">No</button>
		</div>

	</div>

</div>

</template>

<script>

import Cookies from 'js-cookie'

export default {

	name: 'App',

	data: function() {

		return {
			prompt: {
				show: false,
				location: '',
				current: '',
				code: ''
			}
		}

	},

	created: async function() {

		await this.$store.dispatch('init')

		if (this.geolocation) this.promptLocation()

	},

	computed: {

		geolocation: function() {

			return this.$store.getters['geolocation']

		},

		languages: function() {

			return this.$store.getters['languages']

		}

	},

	watch: {

		geolocation: function(n) {

			if (n) this.promptLocation()

		},

		languages: function(n) {

			if (n.length) this.promptLocation()

		}

	},

	methods: {

		onFollowPrompt: function() {

			this.$router.push(this.$link('', this.$store.getters['context'].url, this.prompt.code))

			this.prompt.show = false

		},

		onClosePrompt: function() {

			this.prompt.show = false
					
			Cookies.set('prompted', true)

		},

		promptLocation: function() {

			var asked = Cookies.get('prompted')

			if (asked === undefined && this.languages.length && !this.$route.meta.landing) {

				var suggestedLanguage = this.$store.getters['geolocation']
				var matchedLanguage = false

				this.$_.each(this.$store.getters['languages'], function(candidate) {

					if (candidate.code === suggestedLanguage || this.$_.contains(candidate.alternatives, suggestedLanguage)) {

						matchedLanguage = candidate

					}

				}.bind(this))

				if (matchedLanguage && matchedLanguage.code !== this.$store.getters['language'].code) {

					this.prompt.show = true
					this.prompt.location = matchedLanguage.name
					this.prompt.code = matchedLanguage.code
					this.prompt.current = this.$store.getters['language'].name

				}

			}

		}

	}

}

</script>

<style>

#app {
	min-height: 100vh;
}

#app.is-embed {
	background-color: #eee;
}

.region {
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 100%;
	text-align: center;
	padding: 20px;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	z-index: 10000;
	background-color: #1a234c;
}

.region-text {
	margin-bottom: 20px;
}

.region-buttons {
	display: flex;
	justify-content: center;
}

.region-buttons-item {
	background-color: #fff;
	border-radius: 4px;
	line-height: 40px;
	padding: 0px 10px;
	min-width: 120px;
	margin: 0px 10px;
	font-weight: bold;
	color: #1a234c;
}

</style>
