<template>

<div class="block-wrapper is-aa" :class="{'has-left': page.layout.left.length, 'has-right': page.layout.right.length}" v-if="!is.loading">

	<div class="block-wrapper-inner">

		<com-block :block="block" />

	</div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['data'],

	components: {
		'com-block': () => import('./page/Block')
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			page: {
				layout: {
					left: [],
					right: []
				}
			},
			block: {
				type: 0,
				data: {}
			}
		}

	},

	created: async function() {

		await this.load()

	},

	methods: {

		load: function() {

			return this.$api.get('blocks/' + this.$route.params.id).then(function(json) {

				Vue.set(this, 'block', json.block)
				Vue.set(this, 'page', json.page)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.block-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 200px;
	padding: 2px 20px;
	overflow-y: auto;
	overflow-x: hidden;
}

.block-wrapper-inner {
	background-color: #fff;
	padding: 20px;
	width: 1120px;
	margin: auto 0px;
	pointer-events: none;
}

.block-wrapper.has-left .block-wrapper-inner {
	width: 860px;
}

.block-wrapper.has-right .block-wrapper-inner {
	width: 860px;
}

.block-wrapper.has-right.has-left .block-wrapper-inner {
	width: 600px;
}

</style>
