import _ from 'underscore'

var logs = []

export default {

	init: function() {

	},

	$received: function(id) {

		var received = _.contains(logs, id)

		if (!received) logs.push(id)

		return received

	}

}