import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		geolocation: false,
		context: {
			id: 1,
			url: 'aa'
		},
		languages: [],
		language: {
			code: 'UK'
		}
	},
	
	getters: {

		geolocation: function(state) {

			return state.geolocation

		},

		context: function(state) {

			return state.context

		},

		language: function(state) {

			return state.language

		},

		languages: function(state) {

			return state.languages

		}

	},

	mutations: {

		geolocation: function(state, value) {

			state.geolocation = value

		},	

		context: function(state, value) {

			Vue.set(this.state, 'context', value)

		},

		language: function(state, value) {

			Vue.set(this.state, 'language', value)

		},

		languages: function(state, value) {

			Vue.set(this.state, 'languages', value)

		}

	},

	actions: {

		init: function(context) {

			if (Cookies.get('geolocation') !== undefined) {

				context.commit('geolocation', Cookies.get('geolocation'))

			}

		},
	
		geolocate: function(context) {

			axios.get('https://ipapi.co/json', {
				params: {
					fields: 'countryCode,country'
				}
			}).then(function(response) {

				if (response.data.country_code) {

					context.commit('geolocation', response.data.country_code.toUpperCase())

					Cookies.set('geolocation', context.state.geolocation)

				}

			})

		}

	}

})
