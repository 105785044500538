import Vue from 'vue'
import Router from 'vue-router'

import Page from '@/components/Page'
import Preview from '@/components/Preview'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.VUE_APP_BASE,
	routes: [
		{ name: 'Preview', path: '/preview/:id', meta: { embed: true }, component: Preview },
		{ name: 'Landing', path: '/', meta: { landing: true }, component: Page },
		{ name: 'Page', path: '*', meta: { }, component: Page }
	]
})